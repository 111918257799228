<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper">
        <layouts></layouts>
        <sidebar-menu></sidebar-menu>
        <div class="page-wrapper">
            <div class="content">
                <pageaddheader :title="title" :title1="title1" :items="items" />
                <div class="card">
                    <div class="card-body">
                        <div class="table-top ">
                            <div class="d-flex justify-content-start gap-2 w-100 ">
                                <div class="">
                                    <div class="form-group">
                                        <label>Date </label>
                                        <div class="input-groupicon" style="z-index: 100;">
                                            <datepicker v-model="currentDate" class="picker" :editable="true"
                                                :clearable="false" required @update:modelValue="index" />
                                            <a class="addonset">
                                                <img src="../../../assets/img/icons/calendars.svg" alt="img" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-25">
                                    <div class="form-group">
                                        <label>Filter<span class="text-danger">*</span></label>
                                        <vue-select :options="Jeniskehadiranlist"
                                            :settings="{ settingOption: value, settingOption: value }"
                                            v-model="jenis_kehadiran" placeholder="None">
                                        </vue-select>
                                    </div>
                                </div>
                                <div class="search-set">
                                    <!-- <div class="search-path">
                                    <a class="btn btn-filter" id="filter_search" v-on:click="filter = !filter"
                                        :class="{ setclose: filter }">
                                        <img src="../../../assets/img/icons/filter.svg" alt="img" />
                                        <span><img src="../../../assets/img/icons/closes.svg" alt="img" /></span>
                                    </a>
                                </div> -->

                                    <div class="search-input">
                                        <a class="btn btn-searchset"><img
                                                src="../../../assets/img/icons/search-white.svg" alt="img" /></a>
                                        <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                            <label>
                                                <input type="search" class="form-control form-control-sm"
                                                    placeholder="Search..." aria-controls="DataTables_Table_0"
                                                    v-model="query" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="wordset">
                                <ul>
                                    <li class="" v-if="showSyncHistoryButton != ''">
                                        <router-link class=" fw-bold text-success" to="importattendance"><vue-feather
                                                type="upload"></vue-feather></router-link>
                                    </li>

                                    <li v-if="showSyncHistoryButton != ''">
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="sync"
                                            @click="syncHistory"><vue-feather type="repeat"
                                                class="text-success"></vue-feather></a>
                                    </li>


                                    <!-- <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="pdf"><img
                                                src="../../../assets/img/icons/pdf.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="excel"><img
                                                src="../../../assets/img/icons/excel.svg" alt="img" /></a>
                                    </li>
                                    <li>
                                        <a data-bs-toggle="tooltip" data-bs-placement="top" title="print"><img
                                                src="../../../assets/img/icons/printer.svg" alt="img" /></a>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <!-- /Filter -->


                        <div class="table-responsive">
                            <a-table :columns="columns" :data-source="filteredData">
                                <template #bodyCell="{ column, record }">
                                    <template v-if="column.key === 'no'">
                                        <div class="productimgname">
                                            {{ record.no }}
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'nama'">
                                        <div class="productimgname">
                                            <!---<a href="javascript:void(0);" class="product-img">
                                                <img :src="require('@/assets/img/product/' + record.img)"
                                                    alt="product" />
                                            </a>-->
                                            <a href="javascript:void(0);">{{ record.nama }}</a>
                                        </div>
                                    </template>
                                    <template v-if="column.key === 'jam_masuk'">
                                        <div class="productimgname d-flex flex-column">
                                            <div>{{ record.jam_masuk }}</div>
                                            <div href="javascript:void(0);" class="product-img"
                                                v-if="record.gambar.length != 0 && record.gambar[0] != undefined">

                                                <img :src="record.gambar[0].gambar_kecil"
                                                    @click="openInNewTab(record.gambar[0].gambar_asli)" alt="clockin"
                                                    style="cursor: pointer;" />


                                            </div>

                                        </div>
                                    </template>
                                    <template v-if="column.key === 'jam_pulang'">
                                        <div class="productimgname d-flex flex-column">
                                            <div>{{ record.jam_pulang }}</div>
                                            <div href="javascript:void(0);" class="product-img"
                                                v-if="record.gambar.length != 0 && record.gambar[1] != undefined">
                                                <img :src="record.gambar[1].gambar_kecil"
                                                    @click="openInNewTab(record.gambar[1].gambar_asli)" alt="clockout"
                                                    style="cursor: pointer;" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else-if="column.key === 'action'">
                                        <router-link class="me-3" to="editattendance" @click="edit(record);">
                                            <img src="../../../assets/img/icons/edit.svg" alt="img" />
                                        </router-link>
                                        <a class="confirm-text" href="javascript:void(0);"
                                            @click="terminate(record.last_id, record.tanggal_asli, record.employee_id); showModal('terminate');">
                                            <img src="@/assets/img/icons/delete.svg" alt="img" />
                                        </a>
                                    </template>
                                </template>
                            </a-table>
                        </div>
                    </div>
                </div>
                <!-- <div class="d-flex justify-content-between ">
                    <div class="" v-if="showSyncHistoryButton != ''">
                        <router-link class="btn btn-primary fw-bold" to="importattendance">Import Attendance</router-link>
                    </div>
                </div> -->
                <div class="row">


                </div>


                <!-- /product list -->
                <!-- <div class="activity">
                    <div class="activity-box">
                        <ul class="activity-list">
                            <li v-for="(item, index) in filteredData" :key="index">
                                
                                <a href="javascript:void(0);"
                                    class="text-secondary position-absolute top-0 end-0 me-2 mt-2">{{
                                        item.tanggal
                                    }}</a>
                                <span class="fw-bold">{{ item.nama }}</span>

                                <div class="timeline-content">

                                    <div class="name d-flex gap-2 align-items-center">
                                        <div class="fw-bold mb-1">
                                            {{ item.jenis_kehadiran }}
                                        </div>

                                        <div class="d-flex gap-2 align-items-end  ">
                                            <div class="d-flex gap-2 align-items-center ">
                                                <div>
                                                    <vue-feather type="arrow-right-circle"
                                                        class="text-success fs-6"></vue-feather>
                                                </div>
                                                <div class="mb-1">
                                                    {{ item.jam_masuk }}
                                                </div>
                                            </div>
                                            <div class="d-flex gap-2 align-items-center ">
                                                <div>
                                                    <vue-feather type="arrow-left-circle"
                                                        class="text-danger fs-6"></vue-feather>
                                                </div>
                                                <div class="mb-1">
                                                    {{ item.jam_masuk }}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div class="activity-content">

                                </div>
                            </li>
                        </ul>
                    </div>
                </div> -->
                <!-- /product list -->
                <!-- <div class="card">
                    <div class="card-body">
                        
                    </div>
                </div> -->

            </div>
        </div>

        <div class="modal fade" id="terminate" ref="terminate" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">Delete Data</h5>
                        <button type="button" class="close" @click="hideModal2('terminate')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="delete2">
                        <div class="modal-body">
                            Are u sure want to delete this data?
                        </div>
                        <div class="modal-footer d-flex justify-content-end">
                            <button type="button" class="btn  btn-secondary"
                                @click="hideModal2('terminate')">cancel</button>
                            <button type="submit" class="btn  btn-danger">Delete</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import Cookies from "js-cookie";
import axios from "axios";
import { ShowLoading, CloseLoading, ErrorConnectionTimeOut, base_url, AlertPopup, openInNewTab, AlertBottom } from "@/assets/js/function";

export default {
    data() {
        return {

            title: "History Attendance",
            title1: "View all history attendance",
            items: [
                {
                    text: "Add New Attendance",
                    addname: "/addattendance",
                },
            ],

            //data
            data: [],
            columns: [
                {
                    title: "No",
                    dataIndex: "no",
                    key: "no",
                    sorter: {
                        compare: (a, b) => {
                            a = a.no.toLowerCase();
                            b = b.no.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Name",
                    dataIndex: "nama",
                    key: "nama",
                    sorter: {
                        compare: (a, b) => {
                            a = a.nama.toLowerCase();
                            b = b.nama.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Date",
                    dataIndex: "tanggal",
                    key: "tanggal",
                    sorter: {
                        compare: (a, b) => {
                            a = a.tanggal.toLowerCase();
                            b = b.tanggal.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Type of Attendance",
                    dataIndex: "jenis_kehadiran",
                    key: "jenis_kehadiran",
                    sorter: {
                        compare: (a, b) => {
                            a = a.jenis_kehadiran.toLowerCase();
                            b = b.jenis_kehadiran.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Clock in",
                    dataIndex: "jam_masuk",
                    key: "jam_masuk",
                    sorter: {
                        compare: (a, b) => {
                            a = a.jam_masuk.toLowerCase();
                            b = b.jam_masuk.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },
                {
                    title: "Clock Out",
                    dataIndex: "jam_pulang",
                    key: "jam_pulang",
                    sorter: {
                        compare: (a, b) => {
                            a = a.jam_pulang.toLowerCase();
                            b = b.jam_pulang.toLowerCase();
                            return a > b ? -1 : b > a ? 1 : 0;
                        },
                    },
                },


                {
                    title: "Action",
                    key: "action",
                    sorter: false,
                },
            ],

            api: "",
            config: "",
            currentDate: new Date(),
            jenis_kehadiran: "SEMUA",
            Jeniskehadiranlist: [
                {
                    id: 'SEMUA',
                    text: "ALL",
                },
                {
                    id: "HADIR",
                    text: "HADIR",
                },
                {
                    id: "IZIN",
                    text: "IZIN",
                },
                {
                    id: "TERLAMBAT",
                    text: "TERLAMBAT",
                },
                {
                    id: "ABSEN",
                    text: "ABSENT",
                },
                {
                    id: "LEMBUR",
                    text: "LEMBUR",
                },
            ],

            //filter
            value: "",
            query: "",
            showSyncHistoryButton: "",
            id_terminate: "",
            tanggal_terminate: "",
            employee_id_terminate: "",
        };

    },
    name: "activities",

    created() {
        const token = Cookies.get("token_refresh");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }
        this.index();
    }
    ,
    computed: {
        filteredData() {
            // Filter data based on selected jenis_kehadiran
            if (!this.jenis_kehadiran || (this.jenis_kehadiran == 'SEMUA' && this.query == '')) {
                return this.data;
            } else if (this.jenis_kehadiran != 'SEMUA' && this.query == '') {
                return this.data.filter(item => item.jenis_kehadiran === this.jenis_kehadiran);
            } else if (this.jenis_kehadiran == 'SEMUA' && this.query != '') {
                const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison
                return this.data.filter(
                    (item => item.nama.toLowerCase().includes(lowerCaseQuery) ||
                        item.jam_masuk.toLowerCase().includes(lowerCaseQuery) ||
                        item.jam_pulang.toLowerCase().includes(lowerCaseQuery)
                    )
                );

            } else if (this.jenis_kehadiran != 'SEMUA' && this.query != '') {
                const lowerCaseQuery = this.query.toLowerCase(); // Optional: Convert to lowercase for case-insensitive comparison
                return this.data.filter(
                    (item => item.nama.toLowerCase().includes(lowerCaseQuery) && item.jenis_kehadiran === this.jenis_kehadiran ||
                        item.jam_masuk.toLowerCase().includes(lowerCaseQuery) && item.jenis_kehadiran === this.jenis_kehadiran) ||
                    item.jam_pulang.toLowerCase().includes(lowerCaseQuery) && item.jenis_kehadiran === this.jenis_kehadiran
                );
            }

        }
    },

    methods: {
        openInNewTab,
        showModal(modal_name) {
            this.$refs[modal_name].classList.add('show');
            this.$refs[modal_name].style.display = 'block';
        },
        hideModal2(modal_name) {
            this.$refs[modal_name].classList.remove('show');
            this.$refs[modal_name].style.display = 'none';

        },
        index() {
            this.data = [];
            ShowLoading();
            let formData = new FormData();
            const newDate = new Date(this.currentDate);
            const year = newDate.getFullYear();
            const month = String(newDate.getMonth() + 1).padStart(2, '0');
            const day = String(newDate.getDate()).padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;

            formData.append("tanggal", formattedDate);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'owners/history';
            } else {
                this.api = base_url + 'owners/history';
            }

            const config = {
                headers: { Authorization: `Bearer ` + Cookies.get("token_refresh") },
                timeout: 0,
            }
            axios
                .post(this.api, formData, config)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.status == true) {

                        response.data.data.forEach((item, index) => {
                            let item_prepare = { ...item, no: (index + 1), };
                            this.data.push(item_prepare);
                        });
                        // save location to local
                        localStorage.setItem('locationList', JSON.stringify(response.data.data_lokasi));
                        // save employee to local
                        localStorage.setItem('employeeList', JSON.stringify(response.data.data_employee));

                        this.showSyncHistoryButton = response.data.isanviz;
                        CloseLoading();
                    }


                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });

        },
        syncHistory() {
            ShowLoading();

            if (process.env.NODE_ENV === "development") {
                this.api = this.showSyncHistoryButton;
            } else {
                this.api = this.showSyncHistoryButton;
            }

            const config = {
                headers: { Authorization: `Bearer ` + Cookies.get("token_refresh") },
                timeout: 0,
            }

            axios
                .post(this.api, '', config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.data.message);
                        this.data = [];
                        this.index();
                        CloseLoading();
                    }

                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });

        },
        terminate(id, tanggal_terminate, employee_id) {
            this.id_terminate = id;
            this.tanggal_terminate = tanggal_terminate;
            this.employee_id_terminate = employee_id

        },
        delete2() {
            ShowLoading();
            let formData = new FormData();
            formData.append("last_id", this.id_terminate);
            formData.append("employee_id", this.employee_id_terminate);
            formData.append("tanggal", this.tanggal_terminate);


            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'kehadiran/delete';
            } else {
                this.api = base_url + 'kehadiran/delete';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        const index = this.data.findIndex((record) => record.id === this.id_terminate);
                        this.data.splice(index, 1);
                        CloseLoading();
                        AlertBottom(response.data.message);
                        this.hideModal2('terminate');
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {

                    ErrorConnectionTimeOut(error);
                });


        },
        edit(record) {
            localStorage.setItem('currentData', JSON.stringify(record));
        },
    }
};
</script>